import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  StyleProps,
} from "@chakra-ui/react";
import { Control, Controller, FieldError } from "react-hook-form";
import {
  isoAndDateToISO,
  isoAndTimeToISO,
  isoToDate,
  isoToTime,
} from "../../services/timezone";

interface StyledDateTimeInputProps {
  name: string;
  control: Control<any>;
  defaultValue: string;
  timezone: string;
  label?: string;
  error?: FieldError;
  rules?: any;
  timeOnly?: boolean;
  styles?: StyleProps;
}

// data is of the format { date: string, time: string }
export const StyledDateTimeInput: React.FC<StyledDateTimeInputProps> = ({
  name,
  label,
  defaultValue,
  timezone,
  timeOnly = false,
  control,
  error,
  rules,
  styles: stylesProp,
}) => {
  const styles = {
    width: "100%",
    ...stylesProp,
  };

  return (
    <FormControl isInvalid={!!error} {...styles}>
      {label && (
        <FormLabel htmlFor={name} display="inline-block">
          {label}
        </FormLabel>
      )}
      <InputGroup>
        <Controller
          name={name}
          rules={rules}
          control={control}
          render={({ field }) => (
            <>
              <Input
                name={`${name}.date`}
                type="date"
                onChange={(e) => {
                  if (!e.target.value) return;
                  const iso = isoAndDateToISO(
                    field.value || defaultValue,
                    e.target.value,
                    timezone
                  );

                  field.onChange(iso);
                }}
                value={(() => {
                  return field.value
                    ? isoToDate(field.value, timezone)
                    : isoToDate(defaultValue, timezone);
                })()}
                visibility={timeOnly ? "hidden" : "visible"}
                width={timeOnly ? 0 : 48}
                paddingX={timeOnly ? 0 : 4}
                marginRight={timeOnly ? 0 : 4}
                backgroundColor="white"
                border="2px solid"
              />
              <Input
                name={`${name}.time`}
                type="time"
                onChange={(e) => {
                  const iso = isoAndTimeToISO(
                    field.value || defaultValue,
                    e.target.value,
                    timezone
                  );
                  field.onChange(iso);
                }}
                value={(() => {
                  return field.value
                    ? isoToTime(field.value, timezone)
                    : isoToTime(defaultValue, timezone);
                })()}
                width={48}
                backgroundColor="white"
                border="2px solid"
              />
            </>
          )}
        />
      </InputGroup>
      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};
