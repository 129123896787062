import { Button, HStack } from "@chakra-ui/react";
import { StyledInput } from "../../../../components/forms/StyledInput";
import { useState } from "react";
import { fetchTicketTypeByCode } from "../../../../services/api/ticketTypes";
import { TicketType } from "../../../../types/ticket_types";
import { VianikoEvent } from "../../../../types/events";
import { Panel } from "../../../../components/Panel";

interface TicketTypeCodeFormProps {
  event: VianikoEvent;
  onValidCode: (ticketType: TicketType) => void;
}

export const TicketTypeCodeForm: React.FC<TicketTypeCodeFormProps> = ({
  event,
  onValidCode,
}) => {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");

  const handleApplyCode = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!!code) {
      try {
        const ticketType = await fetchTicketTypeByCode(event.id, code);
        setCode("");
        onValidCode(ticketType);
      } catch (e) {
        setError("Invalid code");
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleApplyCode(e as any);
    }
  };

  return (
    <Panel>
      <HStack align="start">
        <StyledInput
          name="code"
          isRequired
          error={error ? { type: "Error", message: error } : undefined}
          inputProps={{
            value: code,
            onChange: (e) => setCode(e.target.value),
            onKeyDown: handleKeyDown,
          }}
        />
        <Button variant="primary" onClick={handleApplyCode}>
          Apply
        </Button>
      </HStack>
    </Panel>
  );
};
