import { Heading } from "@chakra-ui/react";
import { SubmitHandler } from "react-hook-form";
import { VianikoEvent } from "../../types/events";
import * as api from "../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import { EventForm } from "./components/forms/EventForm";
import { useCurrentOrganizationUser } from "../../providers/CurrentOrganizationUserProvider";
import {
  eventShowUrl,
  homeUrl,
  organizationShowUrl,
} from "../../services/routes/urlBuilder";
import {
  hasPermission,
  PERMISSION_MANAGE_EVENTS,
} from "../../services/permissions";
import { RecurringEvent } from "../../types/recurring_events";

export const NewEventPage: React.FC = () => {
  const navigate = useNavigate();
  const { organizationId } = useParams();
  const { currentOrganizationUser } = useCurrentOrganizationUser();

  const handleSubmit: SubmitHandler<VianikoEvent & RecurringEvent> = async (
    data
  ) => {
    if (data.rrule) {
      await api.recurringEvents.createRecurringEvent(data);
      navigate(
        organizationId ? organizationShowUrl(organizationId) : homeUrl()
      );
    } else {
      const event = await api.events.createEvent(data);
      navigate(eventShowUrl(event.id));
    }
  };

  if (
    !!organizationId &&
    !currentOrganizationUser?.is_owner &&
    !hasPermission(
      currentOrganizationUser?.permissions,
      PERMISSION_MANAGE_EVENTS
    )
  )
    return null;

  return (
    <>
      <Heading as="h1" size="lg">
        Create Event
      </Heading>
      <EventForm onSubmit={handleSubmit} submitText="Create event" />
    </>
  );
};
