import { useForm } from "react-hook-form";
import { VianikoEvent } from "../../../../types/events";
import { StyledRegisteredInput } from "../../../../components/forms/StyledRegisteredInput";
import { useParams, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Select,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { StyledDateTimeInput } from "../../../../components/forms/StyledDateTimeInput";
import { OrganizationUserSelectInput } from "../../../../components/OrganizationUserSelectInput";
import { PaymentConfigurationFormSection } from "./PaymentConfigurationFormSection";
import { Panel } from "../../../../components/Panel";
import { useOrganization } from "../../../../providers/CurrentOrganizationProvider";
import { StyledTextarea } from "../../../../components/forms/StyledTextarea";
import { addHours, roundToNearestHours } from "date-fns";
import { timezoneOptions } from "../../../../services/timezoneOptions";
import { useEffect, useMemo, useState } from "react";
import { useAllRelatedEvents } from "../../../../hooks/useAllRelatedEvents";
import { selectThemeProps } from "../../../../services/theme/overrides/select";
import { PhotoUploadInput } from "../../../../components/forms/PhotoUploadInput";
import { RecurringEvent } from "../../../../types/recurring_events";
import { FrequencyFormSection } from "../../../recurring_events/components/FrequencyFormSection";
import { ControlledNumberField } from "../../../../components/forms/ControlledNumberField";

interface EventFormProps {
  onSubmit: (data: VianikoEvent & RecurringEvent, dirtyFields?: any) => void;
  submitText?: string;
  defaultValues?: VianikoEvent & RecurringEvent;
  formAddon?: React.ReactNode;
  timeOnly?: boolean;
  hideRecurring?: boolean;
}

export const EventForm: React.FC<EventFormProps> = ({
  onSubmit: onSubmitProp,
  submitText,
  defaultValues,
  formAddon,
  timeOnly = false,
  hideRecurring = false,
}) => {
  const [isRecurring, setIsRecurring] = useState(!!defaultValues?.rrule);
  const { organizationId: organizationIdParam } = useParams();
  const [searchParams] = useSearchParams();
  const parentEventIdParam = searchParams.get("parentEventId");

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    setValue,
    watch,
    clearErrors,
    control,
  } = useForm<VianikoEvent & RecurringEvent>({
    defaultValues: { ...defaultValues, num_days_scheduled_ahead: 60 },
    mode: "onBlur",
  });
  const organizationId = organizationIdParam ?? defaultValues?.organization_id;
  const { organization } = useOrganization(organizationId);

  useEffect(() => {
    if (!parentEventIdParam) return;
    setValue("parent_event_id", parentEventIdParam);
  }, [parentEventIdParam, setValue]);

  const timezone = watch("iana_timezone");
  const parentEventId = watch("parent_event_id");
  const { events: allRelatedEvents } = useAllRelatedEvents(parentEventId);

  const selectedTimezoneAbbreviation = useMemo(() => {
    const abbreviation = timezoneOptions.find(
      (option) => option.iana_timezone === timezone
    )?.abbreviation;
    const defaultAbbreviation = timezoneOptions.find(
      (option) =>
        option.iana_timezone ===
        Intl.DateTimeFormat().resolvedOptions().timeZone
    )?.abbreviation;

    return abbreviation || defaultAbbreviation;
  }, [timezone]);

  const onSubmit = (data: VianikoEvent & RecurringEvent) => {
    onSubmitProp(data, dirtyFields);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {formAddon}

      {organizationId && (
        <StyledRegisteredInput
          type="hidden"
          name="organization_id"
          register={register}
          inputProps={{ value: organizationId }}
        />
      )}

      <StyledRegisteredInput
        type="hidden"
        name="max_num_tickets"
        register={register}
        inputProps={{ value: 1 }}
      />

      <Panel>
        <VStack gap={4}>
          <StyledRegisteredInput
            name="name"
            label="Event name"
            type="text"
            register={register}
            options={{
              required: "This field is required",
              maxLength: {
                message: "Must be less than 100 characters",
                value: 100,
              },
            }}
            error={errors.name}
          />

          {parentEventId && (
            <Box width="100%">
              <HStack marginBottom={2}>
                <Text as="label" htmlFor="parent_event_id" size="lg">
                  Session belongs to
                </Text>
                <Spacer />
              </HStack>

              <Select
                variant="primary"
                {...selectThemeProps}
                {...register("parent_event_id")}
              >
                {allRelatedEvents.map((event) => (
                  <option key={event.id} value={event.id}>
                    {event.name}
                  </option>
                ))}
              </Select>
            </Box>
          )}

          <StyledTextarea
            name="description"
            label="Description"
            register={register}
            options={{
              maxLength: {
                message: "Must be less than 20,000 characters",
                value: 20000,
              },
            }}
            error={errors.description}
          />

          <StyledRegisteredInput
            name="location"
            label="Location"
            type="text"
            register={register}
            error={errors.location}
          />
        </VStack>
      </Panel>

      <Panel>
        <VStack gap={4} width="100%">
          <Flex align="start" wrap="wrap" flexDirection="row" width="100%">
            <StyledDateTimeInput
              timeOnly={timeOnly}
              name="start_at"
              label="Start time"
              timezone={watch("iana_timezone")}
              control={control}
              defaultValue={
                defaultValues?.start_at ||
                roundToNearestHours(new Date()).toISOString()
              }
              error={errors.start_at}
              rules={{ required: "This field is required" }}
              styles={{ width: "fit-content", maxWidth: "100%" }}
            />

            <Spacer />

            <Select
              {...register("iana_timezone")}
              variant="subtle"
              defaultValue={
                Intl.DateTimeFormat().resolvedOptions().timeZone ||
                "America/New_York"
              }
              width="100px"
            >
              {timezoneOptions.map((option) => (
                <option key={option.iana_timezone} value={option.iana_timezone}>
                  {selectedTimezoneAbbreviation === option.abbreviation
                    ? option.abbreviation
                    : option.display_name}
                </option>
              ))}
            </Select>
          </Flex>

          <StyledDateTimeInput
            timeOnly={timeOnly}
            name="end_at"
            label="End time"
            timezone={watch("iana_timezone")}
            control={control}
            defaultValue={
              defaultValues?.end_at ||
              roundToNearestHours(addHours(new Date(), 1)).toISOString()
            }
            error={errors.end_at}
            rules={{ required: "This field is required" }}
          />

          {!hideRecurring &&
            (defaultValues?.recurring_event_id || !defaultValues?.id) && (
              <HStack width="100%">
                <Checkbox
                  isChecked={isRecurring}
                  onChange={() => setIsRecurring(!isRecurring)}
                />
                <Text>Repeat</Text>
              </HStack>
            )}

          {isRecurring && (
            <>
              <FrequencyFormSection control={control} name="rrule" />
              <HStack width="100%">
                <Text>Show on calendar</Text>
                <ControlledNumberField
                  name="num_days_scheduled_ahead"
                  control={control}
                />
                <Text>days in advance</Text>
              </HStack>
            </>
          )}
        </VStack>
      </Panel>

      {organizationId && (
        <Panel>
          <VStack gap={4} width="100%">
            <OrganizationUserSelectInput
              name="instructor_id"
              label="Host"
              organizationId={organizationId}
              register={register}
              error={errors.instructor_id}
              clearErrors={clearErrors}
              setValue={setValue}
              defaultValue={defaultValues?.instructor_id}
            />
            <StyledRegisteredInput
              name="capacity"
              label="Capacity"
              type="number"
              register={register}
              options={{
                min: {
                  message: "Must be at least 1",
                  value: 1,
                },
              }}
              error={errors.capacity}
            />
          </VStack>
        </Panel>
      )}

      <Panel>
        <PaymentConfigurationFormSection
          control={control}
          errors={errors}
          organization={organization}
        />
      </Panel>

      <Panel>
        <PhotoUploadInput
          name="photo_ids"
          control={control}
          type="multiple"
          previewProps={{
            height: "124px",
            width: "100%",
          }}
        />
      </Panel>

      <Button
        type="submit"
        variant="primary"
        width="100%"
        marginTop={6}
        marginBottom={12}
      >
        {submitText || "Save"}
      </Button>
    </form>
  );
};
