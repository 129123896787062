import { Control, useController } from "react-hook-form";
import { NumberField } from "./NumberField";

interface ControlledNumberFieldProps {
  name: string;
  control: Control<any>;
}

export const ControlledNumberField: React.FC<ControlledNumberFieldProps> = ({
  name,
  control,
}) => {
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });

  return <NumberField value={value} onChange={onChange} />;
};
