import { HStack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { RRule } from "rrule";
import { Control, useController } from "react-hook-form";
import { DaysOfWeekCheckbox } from "../../../components/forms/DaysOfWeekCheckbox";
import { NumberField } from "../../../components/forms/NumberField";

interface FrequencyFormSectionProps {
  control: Control<any>;
  name: string;
}

export const FrequencyFormSection: React.FC<FrequencyFormSectionProps> = ({
  control,
  name,
}) => {
  const [frequencyInterval, setFrequencyInterval] = useState(1);
  const [selectedDays, setSelectedDays] = useState<number[]>([]);

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      validate: {
        [name]: (value: string) => {
          if (!value) {
            return "This field is required";
          }

          const rrule = RRule.fromString(value);
          if (!rrule.origOptions?.byweekday) {
            return "Please select at least one day of the week";
          } else if (
            !rrule.origOptions?.interval ||
            rrule.origOptions?.interval <= 0
          ) {
            return "Interval must be greater than 0";
          }
        },
      },
    },
  });

  useEffect(() => {
    if (value) {
      const rrule = RRule.fromString(value);
      setFrequencyInterval(rrule.options.interval);
      setSelectedDays(rrule.options.byweekday);
    }
  }, [value]);

  const handleChangeDays = (days: number[]) => {
    onChange(
      new RRule({
        freq: RRule.WEEKLY,
        interval: frequencyInterval,
        byweekday: days,
      }).toString()
    );
  };

  const handleChangeInterval = (interval: number) => {
    onChange(
      new RRule({
        freq: RRule.WEEKLY,
        interval,
        byweekday: selectedDays,
      }).toString()
    );
  };

  return (
    <>
      <HStack width="100%">
        <Text>Repeat every</Text>
        <NumberField
          value={frequencyInterval}
          onChange={handleChangeInterval}
        />
        <Text>{frequencyInterval > 1 ? "weeks" : "week"}</Text>
      </HStack>

      <HStack width="100%">
        <Text marginY={2}>on</Text>
        <DaysOfWeekCheckbox
          defaultValue={selectedDays}
          onChange={handleChangeDays}
        />
      </HStack>

      {error && (
        <Text color="red.500" size="sm" width="100%">
          {error.message}
        </Text>
      )}
    </>
  );
};
