import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { BiMinus, BiPlus } from "react-icons/bi";
import { inputDefaultThemeProps } from "../../services/theme/overrides/input";

interface NumberInputProps {
  value: number;
  onChange: (value: number) => void;
  error?: string;
}

export const NumberField: React.FC<NumberInputProps> = ({
  value,
  onChange,
  error,
}) => {
  return (
    <Box>
      <FormControl isInvalid={!!error} width="100%" marginTop={0}>
        <InputGroup>
          <InputLeftElement marginLeft={1}>
            <Button
              variant="ghost"
              padding={0}
              onClick={() => {
                if (!isNaN(value) && value > 0) {
                  onChange(value - 1);
                } else {
                  onChange(0);
                }
              }}
            >
              <Icon as={BiMinus} />
            </Button>
          </InputLeftElement>

          <Input
            {...inputDefaultThemeProps}
            value={value}
            onChange={(e) => onChange(parseInt(e.target.value) || 0)}
            width="124px"
            textAlign="center"
          />
          <InputRightElement marginRight={1}>
            <Button
              variant="ghost"
              padding={0}
              onClick={() => {
                if (!isNaN(value)) {
                  onChange(value + 1);
                } else {
                  onChange(1);
                }
              }}
            >
              <Icon as={BiPlus} />
            </Button>
          </InputRightElement>
        </InputGroup>
        {error && <FormErrorMessage>{error}</FormErrorMessage>}
      </FormControl>
    </Box>
  );
};
